<template>
  <section class="section">
    <div class="container">
      <div class="content">
        <p class="is-size-4">Cookie Policy</p>
        <p>
          A cookie is a small piece of data (text file) that a website – when
          visited by a user – asks your browser to store on your device in order
          to remember information about you, such as your language preference or
          information. Those cookies are set by us and called first party
          cookies. We also use third party cookies – which are cookies from a
          domain different than the domain of the website you are visiting – for
          our advertising and marketing efforts.
        </p>
        <p>
          More specifically, we use cookies and other tracking technologies for
          the following purposes:
        </p>
        <ul>
          <li>Assisting you in navigation</li>
          <li>
            Assisting in registration to our events, login, and your ability to
            provide feedback
          </li>
          <li>Analysing your use of our products, services or applications</li>
          <li>
            Assisting with our promotional and marketing efforts. (including
            behavioral advertising)
          </li>
        </ul>
        <p>
          Below is a detailed list of the cookies we use on our website. Our
          website is scanned with our cookie scanning tool regularly to maintain
          a list as accurate as possible. We classify cookies in the following
          categories:
        </p>
        <ul>
          <li>Strictly Necessary Cookies</li>
          <li>Performance Cookies</li>
          <li>Functional Cookies</li>
          <li>Targeting Cookies</li>
        </ul>
        <p>
          You can opt-out of each cookie category (except strictly necessary
          cookies) by clicking on the button below:
        </p>
        <!-- OneTrust Cookies Settings button start -->
        <button
          id="ot-sdk-btn"
          class="ot-sdk-show-settings mb-6"
          :class="{ 'button is-loading': $wait.is('loadingOneTrust') }"
        >
          Cookies Settings
        </button>
        <!-- OneTrust Cookies Settings button end -->
        <!-- OneTrust Cookies List start -->
        <button
          v-if="$wait.is('loadingOneTrust')"
          class="button is-white is-loading"
        />
        <div id="ot-sdk-cookie-policy"></div>
        <!-- OneTrust Cookies List end -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CookiesPolicy',
  mounted() {
    this.reloadOneTrustBanner()
    this.clearDuplicateSections()
  },
  methods: {
    reloadOneTrustBanner() {
      // https://my.onetrust.com/s/article/UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046
      const otConsentSdk = document.getElementById('onetrust-consent-sdk')
      if (otConsentSdk) otConsentSdk.remove()

      if (window.OneTrust) {
        this.$wait.start('loadingOneTrust')
        window.OneTrust.Init()

        setTimeout(() => {
          window.OneTrust.LoadBanner()

          const btns = document.getElementsByClassName('ot-sdk-show-settings')

          btns.forEach(button => {
            button.onclick = event => {
              event.stopImmediatePropagation()
              window.OneTrust.ToggleInfoDisplay()
            }
          })

          this.$wait.end('loadingOneTrust')
        }, 1000)
      }
    },
    clearDuplicateSections() {
      // https://my.onetrust.com/s/article/UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046
      const sec = document.getElementById('ot-sdk-cookie-policy')
      const tally = []
      for (let i = sec.length - 1; i >= 0; i--) {
        if (tally[sec[i].firstChild.innerText] === undefined) {
          tally[sec[i].firstChild.innerText] = 1
        } else {
          sec[i].remove()
        }
      }
    }
  }
}
</script>
